import { createUrl } from '@/utils';

const auth = createUrl('v2/cash-register');

const login = auth('auth/login');
const refresh = auth('work/refresh');
const startCr = (crNumber: string) => auth(`work/start/${crNumber}`);
const me = createUrl('auth')('me');

export const authEndpoints = { login, refresh, me, startCr };
