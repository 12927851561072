import StoreConstructor from '@/store/core/StoreConstructor';
import { action } from 'mobx';
import services from '@/api/services';
import { Category, CategoryItem, Nomenclature } from '@/models/nomenclature';
import {
  CalculationTypes,
  CalculationTypesCodes,
  PaymentTypes,
  ReceiptOperationTypes,
  SyntheticReceiptTypes,
} from '@/types/enums';
import {
  IBaseCatalogResponse,
  ICorrectionReceiptPostModel,
  IDefaultReceiptPostModel,
} from '@/types';
import { colorsSet } from '@/types/constants';
import {
  catchError,
  first,
  from,
  map,
  mergeMap,
  Observable,
  throwError,
  toArray,
} from 'rxjs';
import { CorrectionReceipt } from '@/models/CorrectionReceipt';
import { Receipt } from '@/models/Receipt';
import { SaleScreens } from '@/types/sale';
import { LocalCash } from '@/store/localCash';
import router from '@/router';

export default class Sale extends StoreConstructor {
  baseCatalogue: IBaseCatalogResponse | null = null;
  itemsColorsIdx = 0;
  categoriesColorIdx = 0;
  isBaseCatalogLoaded = false;
  colors = colorsSet;
  localCash = new LocalCash();

  @action.bound async getNomenclature(calcType: CalculationTypes) {
    try {
      const nomenclature = await services.sale.nomenclature();
      const transformedNomenclature = {
        ...nomenclature.data,
        isCurrencyNomenclature: false,
        categories: this.transformNomenclature(
          nomenclature.data.categories || [],
          undefined,
          undefined,
        ),
      };
      return this.getNomenclatureByCalcType(transformedNomenclature, calcType);
    } catch (e) {
      console.error(e);
    }
  }

  transformNomenclature(
    categories: Category[],
    color: string | undefined,
    parent: Category | undefined,
  ): Category[] {
    return categories.map((category) => {
      category.parentCategory = parent;
      category.isDisabledByCalcItemAttribute = false;
      category.isCurrencyCategory = false;
      category.items.forEach((item) => {
        item.parentCategory = category;
        item.isDisabledByCalcItemAttribute = false;
        item.isCurrencyCategoryItem = false;
        item.color = this.colors[this.itemsColorsIdx];
        this.itemsColorsIdx =
          this.itemsColorsIdx === this.colors.length - 1
            ? 0
            : this.itemsColorsIdx + 1;
      });

      if (color === null) {
        category.color = this.colors[this.categoriesColorIdx];
        this.categoriesColorIdx =
          this.categoriesColorIdx === this.colors.length - 1
            ? 0
            : this.categoriesColorIdx + 1;
      } else {
        category.color = color;
      }

      if (category.subcategories) {
        category.subcategories = this.transformNomenclature(
          category.subcategories,
          category.color,
          category,
        );
      }

      return category;
    });
  }

  getNomenclatureByCalcType(
    nomenclature: Nomenclature,
    calcType: CalculationTypes,
  ): Nomenclature {
    const { categories, ...others } = nomenclature;
    return {
      ...others,
      categories: this.filterNomenclature(categories || [], calcType),
    };
  }

  filterNomenclature(categories: Category[], calcType: CalculationTypes) {
    return categories
      .map((d) => d)
      .filter((category) => {
        const itemsByCalcType =
          calcType === CalculationTypes.SALE
            ? category.items.filter(
                (i) =>
                  i.product.calcItemAttributeCode !==
                  CalculationTypesCodes.CashOutflow,
              ) // TODO HARDCODE
            : category.items.filter(
                (i) =>
                  i.product.calcItemAttributeCode ===
                  CalculationTypesCodes.CashOutflow,
              );
        const items = itemsByCalcType.filter((i) => !i.disabled);
        items.forEach(
          (i: CategoryItem) => (i.isDisabledByCalcItemAttribute = false),
        );
        category.items = items;
        category.isDisabledByCalcItemAttribute = false;
        const subcategories: Category[] = this.filterNomenclature(
          category.subcategories,
          calcType,
        );
        category.subcategories = subcategories;
        return (
          items.length ||
          subcategories.some((s) => s.items.length || s.subcategories.length)
        );
      });
  }

  @action.bound async getBaseCatalog() {
    try {
      this.baseCatalogue = (await services.sale.baseCatalogue()).data;
    } catch (e) {
      console.error(e);
    }
  }

  sendReceipt(receipt: IDefaultReceiptPostModel): Observable<any> {
    return from(services.sale.sendReceipt(receipt)).pipe(
      first(),
      catchError((err) => throwError(err)),
    );
  }

  sendCorrectionReceipt(
    receipt: Partial<ICorrectionReceiptPostModel>,
  ): Observable<any> {
    return from(services.sale.sendCorrectionReceipt(receipt)).pipe(
      first(),
      catchError((err) => throwError(err)),
    );
  }

  async submitReceipt(
    receipt: CorrectionReceipt | Receipt,
    receiptsByTax: CorrectionReceipt[] | Receipt[],
    shiftStartTime: Date,
    removeFromLocalStore: () => void,
    setStep: ({ step: SaleScreens }: { step: SaleScreens }) => void,
  ): Promise<void> {
    switch (receipt.receiptType) {
      case SyntheticReceiptTypes.INCOME:
        await this.saveReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.INCOME,
          removeFromLocalStore,
          setStep,
        );
        break;
      case SyntheticReceiptTypes.EXPENDITURE:
        await this.saveReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.EXPENDITURE,
          removeFromLocalStore,
          setStep,
        );
        break;
      case SyntheticReceiptTypes.INCOME_RETURN:
        await this.saveReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.INCOME_RETURN,
          removeFromLocalStore,
          setStep,
        );
        break;
      case SyntheticReceiptTypes.EXPENDITURE_RETURN:
        await this.saveReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.EXPENDITURE_RETURN,
          removeFromLocalStore,
          setStep,
        );
        break;

      case SyntheticReceiptTypes.CORRECTION_INCOME:
        await this.saveCorrectionReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.INCOME,
          removeFromLocalStore,
        );
        break;
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE:
        await this.saveCorrectionReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.EXPENDITURE,
          removeFromLocalStore,
        );
        break;
      case SyntheticReceiptTypes.CORRECTION_INCOME_RETURN:
        await this.saveCorrectionReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.INCOME_RETURN,
          removeFromLocalStore,
        );
        break;
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN:
        await this.saveCorrectionReceipt(
          receipt,
          receiptsByTax,
          shiftStartTime,
          ReceiptOperationTypes.EXPENDITURE_RETURN,
          removeFromLocalStore,
        );
        break;
    }
  }

  private async saveReceipt(
    receipt: CorrectionReceipt | Receipt,
    receiptsByTax: CorrectionReceipt[] | Receipt[],
    shiftStartTime: Date,
    operation: ReceiptOperationTypes,
    removeFromLocalStore: () => void,
    setStep: ({ step: SaleScreens }: { step: SaleScreens }) => void,
  ) {
    receipt.setOperation(operation);
    receiptsByTax.forEach((r: CorrectionReceipt | Receipt) =>
      r.setOperation(operation),
    );

    const postModelArray = receiptsByTax.map(
      (r: Receipt) => r.receiptPostModel() as IDefaultReceiptPostModel,
    );
    const receiptServiceList = postModelArray.map((model) => ({
      receipt: model,
      req: this.sendReceipt(model),
    }));
    await from(receiptServiceList)
      .pipe(
        mergeMap((i) =>
          i.req.pipe(map((res) => ({ receipt: i.receipt, res }))),
        ),
        toArray(),
      )
      .toPromise()
      .then((res) => {
        // this.openReceiptResultDialog(res);
        removeFromLocalStore();
      });

    if (receipt.paymentType === PaymentTypes.CASH) {
      if (
        operation === ReceiptOperationTypes.INCOME ||
        operation === ReceiptOperationTypes.EXPENDITURE_RETURN
      ) {
        this.depositFlow(receipt, shiftStartTime);
      } else {
        this.withdrawCash(receipt.totalCashSum, shiftStartTime);
      }
    }

    if (
      operation === ReceiptOperationTypes.EXPENDITURE_RETURN ||
      operation === ReceiptOperationTypes.INCOME_RETURN
    ) {
      // this.appRouterService.goToShiftPage();
      await router.push({ name: 'shift-index' });
    } else {
      setStep({ step: SaleScreens.CATALOG });
    }
  }

  private async saveCorrectionReceipt(
    receipt: CorrectionReceipt | Receipt,
    receiptsByTax: CorrectionReceipt[] | Receipt[],
    shiftStartTime: Date,
    operation: ReceiptOperationTypes,
    removeFromLocalStore: () => void,
  ) {
    receipt.setOperation(operation);
    receiptsByTax.forEach((r) => r.setOperation(operation));

    const postModelArray = (receiptsByTax as CorrectionReceipt[]).map((r) =>
      r.toPostModel(),
    );
    const receiptServiceList = postModelArray.map((model) => ({
      receipt: model,
      req: this.sendCorrectionReceipt(model),
    }));
    await from(receiptServiceList)
      .pipe(
        mergeMap((i) =>
          i.req.pipe(map((res) => ({ receipt: i.receipt, res }))),
        ),
        toArray(),
      )
      .toPromise()
      .then((res) => {
        // this.openReceiptResultDialog(res);
        removeFromLocalStore();
      });

    if (receipt.paymentType === PaymentTypes.CASH) {
      if (
        operation === ReceiptOperationTypes.INCOME ||
        operation === ReceiptOperationTypes.EXPENDITURE_RETURN
      ) {
        this.depositCash(receipt.totalCashSum, shiftStartTime);
      } else {
        this.withdrawCash(receipt.totalCashSum, shiftStartTime);
      }
    }

    // this.appRouterService.goToShiftPage();
    await router.push({ name: 'shift-index' });
  }

  private depositCash(totalCashSum: number, shiftStartTime: Date) {
    this.localCash.depositMoneyByReceipt(totalCashSum, shiftStartTime);
  }

  private withdrawCash(totalCashSum: number, shiftStartTime: Date) {
    this.localCash.withdrawMoneyByReceipt(totalCashSum, shiftStartTime);
  }

  private depositFlow(
    receipt: CorrectionReceipt | Receipt,
    shiftStartTime: Date,
  ) {
    this.depositCash(receipt.receivedCash, shiftStartTime);
    if (receipt.change) {
      this.withdrawCash(receipt.change, shiftStartTime);
    }
  }
}
