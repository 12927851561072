import StoreConstructor from '@/store/core/StoreConstructor';
import moment from 'moment';
import { action, computed, observable } from 'mobx';
import { IShiftState } from '@/api/services/shiftService/types';
import { oc } from 'ts-optchain';
import services from '@/api/services';
import { LocalCash } from '@/store/localCash';
import { RootStores } from '@/store/core/RootStore';
import UserSession from '@/store/session';
import { ILocalCashState, IShiftSummary } from '@/types';

export default class Shift extends StoreConstructor {
  userSession = UserSession;
  transactionsAmountPreset = [50, 100, 200, 500, 1000];

  @observable localCashStore: LocalCash;
  @observable shiftCashState?: ILocalCashState;
  @observable localCashSum? = 0;
  @observable loading = false;
  @observable shiftStateWithSummary?: Partial<IShiftState>;

  constructor(stores: RootStores) {
    super(stores);

    this.localCashStore = new LocalCash();
  }

  @computed get shiftSummaryView() {
    const summary = this.shiftStateWithSummary?.summary;
    if (!summary) return;

    return {
      ...summary,
      shiftNumber: summary.shiftNumber ? '№' + summary.shiftNumber : '-',
      endTime: summary.endTime ? moment(summary.endTime).format('HH:mm') : '-',
      extendedEndTime: moment(summary.endTime).format('MMMM DD YYYY, HH:mm:ss'),
      startTime: moment(summary.startTime).format('HH:mm'),
      extendedStartTime: moment(summary.startTime).format(
        'MMMM DD YYYY, HH:mm:ss',
      ),
    };
  }

  @action.bound mapShiftSummary(fields: any): IShiftSummary {
    const opened = !!fields && !!Object.keys(fields).length;
    const shiftNumber: number = fields && fields[1038] ? fields[1038] : null;
    const startTime: Date | undefined =
      fields && fields[1012]
        ? moment(fields[1012], 'ddd MMM DD HH:mm:ss zz YYYY', 'en-US').toDate()
        : undefined;
    let endTime: Date | undefined = undefined;
    if (startTime != null) {
      endTime = new Date(startTime);
      const maxShiftDuration = '24H';
      const iso8601durationFormat = `PT${maxShiftDuration}`.toUpperCase();
      const duration = moment.duration(iso8601durationFormat);
      endTime = moment(startTime).add(duration).toDate();
    }
    const receiptsCount = fields && fields[1118] ? fields[1118] : 0;
    const cashier = fields && fields[1021] ? fields[1021] : null;
    const crRegistrationNumber = fields && fields[1037] ? fields[1037] : null;
    return {
      cashier,
      crRegistrationNumber,
      opened,
      shiftNumber,
      startTime,
      endTime,
      receiptsCount,
    };
  }

  @action.bound async shiftIsOpened() {
    await this.getShiftStateWithSummary();

    return (
      !this.shiftStateWithSummary?.errorDesc &&
      !!this.shiftStateWithSummary?.summary?.opened
    );
  }

  @action.bound refreshPageData() {
    this.localCashSum = this.localCashStore.localCashSum;
    if (
      this.shiftStateWithSummary?.summary?.opened &&
      this.shiftStateWithSummary?.summary?.startTime
    ) {
      this.shiftCashState = this.localCashStore.cashStateForCurrentShift(
        this.shiftStateWithSummary?.summary?.startTime,
      );
    }
  }

  @action.bound async getShiftStateWithSummary() {
    try {
      const stateResponse = await this.getShiftState();
      const fields = oc(stateResponse.data).fields(null);
      this.shiftStateWithSummary = {
        ...stateResponse.data,
        fields,
        summary: this.mapShiftSummary(fields),
        deregistered: stateResponse && stateResponse.status === 404,
      };
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound async openShift() {
    if (this.userSession?.isCrBlocked) {
      console.log('blocked');
      return;
    } else {
      try {
        this.loading = true;
        await services.shift.open();
        await this.getShiftStateWithSummary();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }

  @action.bound async closeShift() {
    try {
      await services.shift.close();
      this.shiftStateWithSummary = undefined;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound private getShiftState() {
    return services.shift.state();
  }
}
