import StoreConstructor from '@/store/core/StoreConstructor';
import {
  IBaseCatalog,
  IPositionBaseCatalog,
  IPrincipal,
  IPrincipalBaseCatalog,
  ISaleTax,
  ITaxRate,
} from '@/types';
import { from, map, mergeMap, toArray } from 'rxjs';
import services from '@/api/services';
import { Nullable } from '@/types/utilities';

export default class BaseCatalog extends StoreConstructor {
  private _baseCatalog: IBaseCatalog = { taxRates: [], items: [] };
  private _principalsBaseCatalog: IPrincipalBaseCatalog[] = [];

  get hasBaseCatalogItems(): boolean {
    return (
      this._baseCatalog.items != null && this._baseCatalog.items.length > 0
    );
  }

  get baseCatalogItems(): IPositionBaseCatalog[] {
    return this._baseCatalog.items;
  }

  get taxRates(): ITaxRate[] {
    return this._baseCatalog.taxRates;
  }

  async refreshBaseCatalog() {
    try {
      const response = (await services.sale.baseCatalogue()).data;
      this._baseCatalog = {
        items: response.items,
        taxRates: response.taxes.map((tax) => {
          return {
            code: tax.code,
            taxRateType: tax.type,
            taxRateValue: tax.value,
          };
        }),
      };
    } catch (err) {
      console.error(err);
    }
  }

  getTaxRateByCode(
    type: string,
    code: number | null,
    tin?: string,
  ): Nullable<ISaleTax> {
    const rates = tin ? this.getBaseCatalogByTin(tin)?.taxRates : this.taxRates;
    const taxRate = rates?.find(
      (x) => x.code === code && x.taxRateType === type,
    );
    return { code: taxRate?.code || null, rate: taxRate?.taxRateValue || null };
  }

  getTaxRateFromBaseCatalog(
    calcItemAttribute: number | null | undefined,
    taxSystem: number,
    tin?: string,
  ) {
    const items = tin
      ? this.getBaseCatalogByTin(tin)?.items
      : this.baseCatalogItems;
    const basePosition = items?.find(
      (item) =>
        item.code === calcItemAttribute && item.taxSystemCode === taxSystem,
    );

    // для отладки
    if (basePosition == null) {
      console.log(
        `Внимание! Не найдена позиция базового каталога для параметров: calcItemAttribute = ${calcItemAttribute}; taxSystemCode = ${taxSystem}`,
      );
    }

    return basePosition
      ? {
          stCashRate: this.getTaxRateByCode('ST', basePosition.stCashCode, tin),
          stCardRate: this.getTaxRateByCode(
            'ST',
            basePosition.stCashlessCode,
            tin,
          ),
          vatRate: this.getTaxRateByCode('VAT', basePosition.vatCode, tin),
        }
      : {
          stCashRate: null,
          stCardRate: null,
          vatRate: null,
        };
  }

  refreshBaseCatalogPrincipals() {
    const principals = this.stores.principal.principals;
    const requests = principals.map((principal) => ({
      tin: principal.tin,
      request: this.loadBaseCatalogByParams(principal),
    }));

    from(requests)
      .pipe(
        mergeMap((item) =>
          item.request.pipe(map((value) => ({ ...value, tin: item.tin }))),
        ),
        toArray(),
      )
      .subscribe((results) => {
        this._principalsBaseCatalog = results.map((i: any) => ({
          tin: i.tin,
          items: i.items,
          taxRates: i.taxes.map((tax: any) => ({
            code: tax.code,
            taxRateType: tax.type,
            taxRateValue: tax.value,
          })),
        }));
      });
  }

  getTaxSystems() {
    return services.baseCatalog.taxSystems();
  }

  private loadBaseCatalogByParams(principal: IPrincipal) {
    const params = principal
      ? {
          isVatPayer: principal.vatPayer.toString(),
          taxSystems: principal.taxSystems.toString(),
          calcItemAttributeCodes: principal.calcItemAttributes.toString(),
        }
      : null;

    return from(services.baseCatalog.loadByParams(params));
  }

  private getBaseCatalogByTin(tin: string) {
    return this._principalsBaseCatalog.find((bc) => bc.tin === tin);
  }
}
