import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ruLocale from '@/locales/ru.json';
import enLocale from '@/locales/en.json';

import ruKitLocale from '@dtt/ui-kit/src/locale/lang/ru.json';
import enKitLocale from '@dtt/ui-kit/src/locale/lang/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'ru',
  fallbackLocale: 'ru',
  messages: {
    ru: { ...ruLocale, ...ruKitLocale },
    en: { ...enLocale, ...enKitLocale },
  },
});

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  localStorage.setItem('lang', lang);
  return lang;
}

export { i18n, setI18nLanguage };
