import { authEndpoints as auth } from '@/api/endpoints/auth';
import { saleEndpoints as sale } from '@/api/endpoints/sale';
import { shiftEndpoints as shift } from '@/api/endpoints/shift';
import { currencyEndpoints as currency } from '@/api/endpoints/currency';
import { principalEndpoints as principal } from '@/api/endpoints/principal';
import { baseCatalogEndpoints as baseCatalog } from '@/api/endpoints/baseCatalog';

export const ENDPOINTS = {
  auth,
  shift,
  sale,
  currency,
  principal,
  baseCatalog,
};
