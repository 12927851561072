export const EMAIL_REGEX = /^\S{2,}@\S{2,}\.[a-zA-Z]{2,4}$/;

export const GMAP_OPTIONS = {
  zoomControl: true,
  zoomControlOptions: { position: 7 },
  clickableIcons: false,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  minZoom: 5,
  maxZoom: 18,
  center: { lng: 74.570606, lat: 42.875643 },
};

export const GMAP_ZOOM = 12;
export const PAGE_SIZE = 20;

export const RERENDER_TIMEOUT = 15000; // milliseconds
export const USE_CLUSTERS = 12; // google map zoom
export const MIN_DISTANCE = 4000; // minimum distance to create new cluster
export const POLLING_TIMEOUT = 120 * 1000;
export const CURRENCY_FRACTURE_PART = 10000;

export const colorsSet = [
  '#E36397',
  '#FA7921',
  '#72B46A',
  '#06BEE1',
  '#FFD166',
  '#9F6EC4',
  '#3993DD',
  '#41B39E',
  '#F45B69',
  '#ADA938',
];
