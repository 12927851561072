import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { ITaxSystem } from '@/types';

export class BaseCatalogService extends ApiService {
  public async loadByParams(params: Record<string, any> | null) {
    return await axiosInstance.get(ENDPOINTS.baseCatalog.loadByParams, {
      params,
    });
  }

  public async taxSystems() {
    return await axiosInstance.get<ITaxSystem[]>(
      ENDPOINTS.baseCatalog.taxSystems,
    );
  }
}
