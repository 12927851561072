import { ISaleTax, ITaxCounter } from '@/types';
import { PaymentTypeMarks } from '@/types/enums';
import { CategoryItem } from '@/models/nomenclature';

export type ITaxSum = Omit<ITaxCounter, 'rate'>;

export interface IGoodItem {
  guid: string;
  parentGoodItemGuid: string;
  isCurrencyCategoryItem: boolean;
  calcItemAttributeCode: number;
  taxSystem: number;
  quantity: number;
  name: string;
  price: number;
  stCashRate: ISaleTax;
  stCardRate: ISaleTax;
  vatRate: ISaleTax;
  gtin: string;
  qrCode: string;
  marked: boolean;
  vendorCode: string;
  measure: string;
  description: string;
  category?: string;
  subcategory?: string;
  nomenclatureCode?: string;
  nomenclatureCodeHex?: string;
  sgtin?: string;
  principal?: string;
}

export interface IGoodItemPostModel {
  calcItemAttributeCode: number;
  cost: number;
  name: string;
  price: number;
  quantity: number;
  vat: number;
  nomenclatureCodeHex?: string;
  sgtin?: string;
  paymentType: PaymentTypeMarks;
}

export interface IProductRate {
  code: number;
  rate: number;
}

export interface ICategoryItemInfo {
  item: CategoryItem;
  subcategory: string | null;
  category: string | null;
}

export interface ICodeValidationResult {
  hasError: boolean;
  errorKey: string | null;
}

export enum SaleScreens {
  START_SHIFT,
  CATALOG,
  FREE_PRICE,
  PAYMENT,
}
