import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api';
import { IShiftStateResponse } from '@/api/services/shiftService/types';
import { ENDPOINTS } from '@/api/endpoints';

export class ShiftService extends ApiService {
  public open() {
    return axiosInstance.post<IShiftStateResponse>(ENDPOINTS.shift.open);
  }

  public close() {
    return axiosInstance.post<IShiftStateResponse>(ENDPOINTS.shift.close);
  }

  public state() {
    return axiosInstance.post<IShiftStateResponse>(ENDPOINTS.shift.state);
  }
}
