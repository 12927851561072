import { v4 as uuidv4 } from 'uuid';
import { calculateDaysLeft } from '@/utils';
import { IAddress, IPrincipal, IUser } from '@/types';
import { CalculationTypes, CalculationTypesCodes } from '@/types/enums';
import { action, computed, observable } from 'mobx';

export class UserSession implements IUser {
  @observable name?: string;
  @observable login?: string;
  @observable taxSystems: number[];
  @observable vatPayer?: boolean;
  @observable calcItemAttributes: number[];
  @observable entrepreneurshipObject?: number;
  @observable businessActivity?: number;
  @observable taxAuthorityDepartment?: number;
  @observable currencyCatalogUpdateDate?: Date;
  @observable address?: IAddress;
  @observable isEmpty = true;
  @observable fnExpiresAt?: string;
  @observable tin?: string;
  @observable principals: IPrincipal[];

  @observable crInactive: boolean;

  constructor() {
    this.name = '';
    this.login = '';
    this.taxSystems = [];
    this.calcItemAttributes = [];
    this.address = undefined;
    this.isEmpty = true;
    this.fnExpiresAt = undefined;
    this.tin = undefined;
    this.crInactive = false;
    this.currencyCatalogUpdateDate = undefined;
    this.principals = [];
  }

  @computed get formattedAddress(): string {
    return this.address ? this.address.formattedString : '';
  }

  @computed get fnExpiresAtAsDate(): Date | null {
    return this.fnExpiresAt ? new Date(this.fnExpiresAt) : null;
  }

  @computed get fnActivationDaysLeft(): number | undefined {
    if (this.fnExpiresAtAsDate) {
      return calculateDaysLeft(this.fnExpiresAtAsDate);
    }
  }

  @computed get isFnExpired(): boolean {
    return !!this.fnExpiresAt && this.fnActivationDaysLeft === 0;
  }

  @computed get isCrInactive(): boolean {
    return this.crInactive;
  }

  @computed get isCrBlocked() {
    return this.isFnExpired || this.isCrInactive;
  }

  @computed get currencyExchangeCalcTypesCodes(): CalculationTypesCodes[] {
    return [CalculationTypesCodes.ForeignCurrencyExchange];
  }

  @computed get isCurrencyExchange(): boolean {
    return this.calcItemAttributes.every((itemAttrCode: number) =>
      this.currencyExchangeCalcTypesCodes.includes(itemAttrCode),
    );
  }

  @computed get notaryOrLawyerCalcTypesCodes(): CalculationTypesCodes[] {
    return [CalculationTypesCodes.Services, CalculationTypesCodes.StateTax];
  }

  @computed get isNotaryOrLawyer(): boolean {
    return this.calcItemAttributes.every((itemAttrCode: number) =>
      this.notaryOrLawyerCalcTypesCodes.includes(itemAttrCode),
    );
  }

  @computed get calculationType(): CalculationTypes[] {
    const calcTypes = []; // TODO HARDCODE
    if (
      this.calcItemAttributes?.filter(
        (c) => c !== CalculationTypesCodes.CashOutflow,
      ).length > 0
    ) {
      calcTypes.push(CalculationTypes.SALE);
    }

    if (
      this.calcItemAttributes?.includes(CalculationTypesCodes.CashOutflow) ||
      this.calcItemAttributes?.includes(
        CalculationTypesCodes.ForeignCurrencyExchange,
      )
    ) {
      calcTypes.push(CalculationTypes.EXPENSE);
    }

    return calcTypes;
  }

  @action.bound init(opts: IUser) {
    this.name = opts.name;
    this.login = opts.login;
    this.taxSystems = opts.taxSystems;
    this.vatPayer = opts.vatPayer;
    this.calcItemAttributes = opts.calcItemAttributes;
    this.entrepreneurshipObject = opts.entrepreneurshipObject;
    this.businessActivity = opts.businessActivity;
    this.taxAuthorityDepartment = opts.taxAuthorityDepartment;
    this.currencyCatalogUpdateDate = opts.currencyCatalogUpdateDate;
    this.address = opts.address;
    this.isEmpty = false;
    this.fnExpiresAt = opts.fnExpiresAt;
    this.tin = opts.tin;
    this.principals = (opts.principals || []).map((principal: IPrincipal) => ({
      ...principal,
      uuid: uuidv4(),
    }));
  }
}

export default new UserSession();
