import { RouteConfig } from 'vue-router';

const ShiftView = () => import('@/views/shift/shift.vue');
const ShiftIndex = () => import('@/views/shift/index.vue');

export default {
  path: '/shift',
  component: ShiftView,
  children: [
    {
      path: '',
      component: ShiftIndex,
      name: 'shift-index',
    },
  ],
} as RouteConfig;
