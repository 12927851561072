import StoreConstructor from '@/store/core/StoreConstructor';
import { ITaxSystem } from '@/types';
import services from '@/api/services';

export default class TaxSystems extends StoreConstructor {
  taxSystems: ITaxSystem[] = [];

  formattedTaxSystem(userTaxSystems: number[]): string {
    let taxNames = '';
    for (let i = 0; i < userTaxSystems.length; i++) {
      const taxSystem = this.taxSystems.find(
        (t) => t.code === userTaxSystems[i],
      );
      taxNames +=
        (taxSystem ? taxSystem.taxName : '') +
        (i === userTaxSystems.length - 1 ? '' : ', ');
    }

    return taxNames;
  }

  public async refreshTaxSystems() {
    try {
      const taxSystems = (await services.baseCatalog.taxSystems()).data;
      this.taxSystems = taxSystems.sort(
        (a: ITaxSystem, b: ITaxSystem) => a.code - b.code,
      );
    } catch (err) {
      console.error(err);
    }
  }
}
