import { createUrl } from '@/utils';
import { IBaseCurrency } from '@/types';

const currency = createUrl('currency');

const create = currency('create');
const update = currency('update');
const deleteById = (id: IBaseCurrency['id']) => currency(`delete/${id}`);
const list = currency('list');

export const currencyEndpoints = { create, update, deleteById, list };
