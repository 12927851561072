export const createUrl = (basePath: string) => (path: string) =>
  `${basePath}${'/' + path}`;

type NumGroupConfig = {
  incorrectSign?: string;
  fraction?: boolean;
  fractionDigits?: number;
};
const defaultConfig: NumGroupConfig = {
  incorrectSign: '—',
  fraction: true,
  fractionDigits: 2,
};

export function numGroup(
  value: number | null | undefined | '',
  options?: NumGroupConfig,
) {
  const opts = Object.assign(
    { ...defaultConfig },
    options || {},
  ) as Required<NumGroupConfig>;
  if (value === null || value === undefined || value === '' || isNaN(value))
    return opts.incorrectSign;
  return Number(value.toFixed(2)).toLocaleString(undefined, {
    ...(opts.fraction && {
      minimumFractionDigits: opts.fractionDigits,
      maximumFractionDigits: opts.fractionDigits,
    }),
  });
}

export function calculateDaysLeft(targetDate: Date): number {
  const today = new Date();
  const diffTime = targetDate.getTime() - today.getTime();
  return diffTime > 0 ? Math.ceil(diffTime / (1000 * 3600 * 24)) : 0;
}

export const absolute = (value: number) => Math.abs(value);
