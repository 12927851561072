
import { defineComponent } from 'vue';
import moment from 'moment';

const DEFAULT_LAYOUT = 'default';

export default defineComponent({
  watch: {
    '$i18n.locale'() {
      moment.locale(this.$i18n.locale);
    },
  },
  computed: {
    layout(): string {
      return (this.$route.meta?.layout || DEFAULT_LAYOUT) + '-layout';
    },
  },
  beforeMount() {
    moment.updateLocale(moment.locale(), { invalidDate: '-' });
    moment.locale(localStorage.getItem('lang') || this.$i18n.locale);
  },
});
