import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import store from '@/store/core/RootStore';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.key) {
    localStorage.setItem('demoKey', to.query.key as string);
    store.user.demoKey = localStorage.getItem('demoKey');
  }

  if (store.user.isLoggedIn || to.meta?.public) {
    next();
  } else {
    next({ name: 'auth-login-index' });
  }
});

export default router;
