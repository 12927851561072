import { RouteConfig } from 'vue-router';
import AuthRoutes from './auth';
import SaleRoutes from './sale';
import ShiftRoutes from './shift';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'sale-index' },
  },
  AuthRoutes,
  SaleRoutes,
  ShiftRoutes,
];

export default routes;
