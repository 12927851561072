import stores from '@/store/core/RootStore';
import { ILocalCashState, ILocalCashTransaction } from '@/types';

export class LocalCash {
  private LOCAL_STORAGE_KEY = 'shiftState';

  cashStateForCurrentShift(startDateTime: Date): ILocalCashState {
    const state = this.getCashState();
    const filter = (t: ILocalCashTransaction) =>
      !t.byReceipt &&
      t.shiftStartDateTimeIso &&
      t.shiftStartDateTimeIso === startDateTime.toISOString();
    const deposits = state.deposits?.filter(filter);
    const withdraws = state.withdraws?.filter(filter);
    const sum = this.calculateTransactionsSum(deposits, withdraws);
    return {
      deposits: state.deposits?.filter(filter),
      withdraws: state.withdraws?.filter(filter),
      sum: sum,
    };
  }

  getCashState(): ILocalCashState {
    const stateJson = localStorage.getItem(this.localStorageStateKey);
    if (stateJson) {
      const state = JSON.parse(stateJson) as ILocalCashState;
      if (this.isNullOrUndefined(state.sum)) {
        state.sum = this.calculateTransactionsSum(
          state.deposits,
          state.withdraws,
        );
        this.setLocalState(state);
      }
      return state;
    }
    return this.createLocalState();
  }

  get localCashSum(): number | undefined {
    const state = this.getCashState();
    if (!state) {
      return 0;
    }
    return state.sum;
  }

  depositMoneyByReceipt(sum: number, shiftStartDate: Date) {
    this.depositMoney(sum, shiftStartDate, true);
  }

  manualDepositMoney(sum: number, shiftStartDate: Date | null = null) {
    this.depositMoney(sum, shiftStartDate, false);
  }

  withdrawMoneyByReceipt(sum: number, date: Date) {
    return this.withdrawMoney(sum, date, true);
  }

  manualWithdrawMoney(sum: number, date: Date | null = null) {
    return this.withdrawMoney(sum, date, false);
  }

  private withdrawMoney(
    sum: number,
    date: Date | null = null,
    byReceipt: boolean,
  ) {
    const existingState = this.getCashState();
    if (existingState) {
      const transaction: ILocalCashTransaction = {
        sum,
        byReceipt,
        shiftStartDateTimeIso: date ? date.toISOString() : undefined,
      };
      existingState.withdraws = [...existingState.withdraws, transaction];
      existingState.sum = this.calculateTransactionsSum(
        existingState.deposits,
        existingState.withdraws,
      );
      this.setLocalState(existingState);
    }
  }

  private depositMoney(sum: number, date: Date | null, byReceipt?: boolean) {
    const existingState = this.getCashState();
    if (existingState) {
      const transaction: ILocalCashTransaction = {
        sum,
        byReceipt,
        shiftStartDateTimeIso: date ? date.toISOString() : undefined,
      };
      existingState.deposits = [...existingState.deposits, transaction];
      existingState.sum = this.calculateTransactionsSum(
        existingState.deposits,
        existingState.withdraws,
      );
      this.setLocalState(existingState);
    }
  }

  private calculateTransactionsSum(
    deposits: ILocalCashTransaction[],
    withdraws: ILocalCashTransaction[],
  ) {
    const reducer = (a: number, b: { sum: number }) => a + b.sum;
    const depositsSum = deposits ? deposits.reduce(reducer, 0) : 0;
    const withdrawsSum = withdraws ? withdraws.reduce(reducer, 0) : 0;
    return depositsSum - withdrawsSum;
  }

  private createLocalState(): ILocalCashState {
    const state: ILocalCashState = { deposits: [], withdraws: [], sum: 0 };
    this.setLocalState(state);
    return state;
  }

  private setLocalState(state: ILocalCashState) {
    localStorage.setItem(this.localStorageStateKey, JSON.stringify(state));
  }

  private get localStorageStateKey(): string {
    return `${this.LOCAL_STORAGE_KEY}_${stores.user.activeCr}`;
  }

  private isNullOrUndefined(val: any) {
    return val === null || val === undefined;
  }
}
