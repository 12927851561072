import User from '@/store/user';
import BaseCatalog from '@/store/baseCatalog';
import Shift from '@/store/shift';
import Sale from '@/store/sale';
import { Principals } from '@/store/principals';
import { Currency } from '@/store/currency';
import TaxSystems from '@/store/taxSystems';

export class RootStores {
  user = new User(this);
  baseCatalog = new BaseCatalog(this);
  shift = new Shift(this);
  sale = new Sale(this);
  principal = new Principals(this);
  currency = new Currency(this);
  taxSystems = new TaxSystems(this);
}

export default new RootStores();
