import { RouteConfig } from 'vue-router';
import { CalculationTypes, SyntheticReceiptTypes } from '@/types/enums';

const SaleView = () => import('@/views/sale/sale.vue');
const SaleIndex = () => import('@/views/sale/index.vue');
const SaleBaseCatalog = () => import('@/views/sale/base-catalog.vue');

export default {
  path: '/sale',
  component: SaleView,
  children: [
    {
      path: '',
      component: SaleIndex,
      name: 'sale-index',
      meta: {
        calcType: CalculationTypes.SALE,
        receiptType: SyntheticReceiptTypes.INCOME,
      },
    },
    {
      path: 'base-catalog',
      component: SaleBaseCatalog,
      name: 'sale-base-catalog',
    },
  ],
} as RouteConfig;
