export enum TaxpayerRiskEnum {
  NOT_SET = 'NOT_SET',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum TaxSystemEnum {
  GENERAL = 'GENERAL',
  SIMPLIFIED = 'SIMPLIFIED',
  SPECIAL = 'SPECIAL',
}

export enum KktStatusEnum {
  REQUESTED = 'REQUESTED',
  REGISTERED = 'REGISTERED',
  REJECTED = 'REJECTED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum BackendReceiptOperationTypes {
  INCOME = 1,
  INCOME_RETURN = 2,
  EXPENDITURE = 3,
  EXPENDITURE_RETURN = 4,
}

export enum ReceiptOperationTypes {
  INCOME = 'INCOME',
  INCOME_RETURN = 'INCOME_RETURN',
  EXPENDITURE = 'EXPENDITURE',
  EXPENDITURE_RETURN = 'EXPENDITURE_RETURN',
}

export enum BackendReceiptTypes {
  REGISTRATION_REPORT = 1,
  REGISTRATION_CHANGE_REPORT = 11,
  OPEN_SHIFT_REPORT = 2,
  CURRENT_STATE_REPORT = 21,
  TICKET = 3,
  CORRECTION_TICKET = 31,
  ACC_FORM = 4,
  CORRECTION_ACC_FORM = 41,
  CLOSE_SHIFT_REPORT = 5,
  CLOSE_FN_REPORT = 6,
  OPERATOR_ACK = 7,
}

export enum ReceiptTypes {
  TICKET = 'TICKET',
  CORRECTION_TICKET = 'CORRECTION_TICKET',
}

export enum TaxTypes {
  VAT = 'VAT',
  ST = 'ST',
}

export enum SyntheticReceiptTypes {
  INCOME = 'INCOME',
  INCOME_RETURN = 'INCOME_RETURN',
  EXPENDITURE = 'EXPENDITURE',
  EXPENDITURE_RETURN = 'EXPENDITURE_RETURN',

  CORRECTION_INCOME = 'CORRECTION_INCOME',
  CORRECTION_INCOME_RETURN = 'CORRECTION_INCOME_RETURN',
  CORRECTION_EXPENDITURE = 'CORRECTION_EXPENDITURE',
  CORRECTION_EXPENDITURE_RETURN = 'CORRECTION_EXPENDITURE_RETURN',
}

export enum CorrectionReceiptTypes {
  MANUAL = 0,
  ON_REQUEST = 1,
}

export enum ShiftReportTypes {
  Z = 'Z',
  X = 'X',
  OPENING = 'OPENING',
}

export enum FormatAndLogicControlErrorsSeverityType {
  // Критичная ошибка
  CRITICAL = 'CRITICAL',

  // Приемлимая ошибка
  ACCEPTABLE = 'ACCEPTABLE',
}

export enum FdErrorFilter {
  // чеки без ошибок
  NO_ERRORS = 'NO_ERRORS',

  // чеки с некритическими ошибками
  ACCEPTABLE_ERRORS = 'ACCEPTABLE_ERRORS',

  // чеки с критическими ошибками
  CRITICAL_ERRORS = 'CRITICAL_ERRORS',

  // чеки без ошибок и с некритическими ошибками: NO_ERRORS + ACCEPTABLE_ERRORS
  VALID = 'VALID',

  // все чеки
  ALL = 'ALL',
}

export enum AnalyticsSummaryTypes {
  BY_RECEIPTS = 'BY_RECEIPTS',
  BY_AGENT_RECEIPTS = 'BY_AGENT_RECEIPTS',
  BY_CASH_REGISTERS = 'BY_CASH_REGISTERS',
  BY_RECEIPTS_WITH_CRITICAL_ERRORS = 'BY_RECEIPTS_WITH_CRITICAL_ERRORS',
}

export enum AnalyticsDateFilterTypes {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum PaymentTypes {
  CASH = 'CASH',
  CARD = 'CARD',
  ADVANCE = 'ADVANCE',
  CREDIT = 'CREDIT',
}

export enum PaymentTypeMarks {
  // полный расчет
  FullSettlement = 0,

  // предоплата (аванс)
  Advance = 1,

  // частичный расчет и кредит
  PartialSettlementWithCredit = 2,

  // передача в кредит
  Credit = 3,

  // оплата кредита
  CreditSettlement = 4,
}

// Коды систем налогообложения (СНО), они должны совпадать с данными из конструктора налоговых ставок в АИС НО
export enum TaxSystemCodes {
  // Общий налоговый режим
  Common = 0,

  // Налог на основе обязательного патента
  MandatoryPatent = 1,

  // Налог на основе патента
  Patent = 2,

  // Упрощенная система налогообложения на основе единого налога
  SimplifiedBasedOnSingleTax = 3,

  // Налоги на основе налогового контракта
  TaxContract = 4,

  // Налоговый режим в свободных экономических зонах
  FreeEconomicZones = 5,

  // Налоговый режим в Парке высоких технологий
  HiTechPark = 6,

  // Упрощенная система налогообложения на основе налога с розничных продаж
  SimplifiedBasedOnRetailTax = 7,

  // Налог на майнинг
  Mining = 8,

  // Налог на деятельность в сфере электронной торговли
  ECommerce = 9,
}

// Коды признаков предметов расчетов (ППР), они должны совпадать с данными из конструктора налоговых ставок в АИС НО
export enum CalculationTypesCodes {
  // Товар
  Goods = 0,

  // Услуги или работы
  Services = 1,

  // Предмет расчета, реализуемый банком ИЛИ Расчеты за банковские услуги (обмен валюты)
  BankingServices = 2,

  // Услуги сотовой связи
  CellularService = 3,

  // Выплата денежных средств
  CashOutflow = 4,

  // Авансовый платеж
  AdvancePayment = 5,

  // Обмен иностранной валюты обменным бюро
  ForeignCurrencyExchange = 6,

  // Необлагаемые поставки
  Untaxed = 7,

  //Кредитный платеж
  CreditPayment = 8,

  // Государственная пошлина
  StateTax = 9,
}

export enum VatTypes {
  VAT_0 = 0,
  VAT_12 = 1,
}

export enum StTypes {
  ST_0 = 0,
  ST_1 = 1,
  ST_2 = 2,
  ST_3 = 3,
  ST_5 = 4,
}

export enum RegHistoryRecordType {
  registration = 1,
  closeFn = 6,
  changeAddressOrTaxSystem = 11,
}

export enum CalculationTypes {
  SALE = 'SALE',
  EXPENSE = 'EXPENSE',
}

export enum DataAlreadyExistErrors {
  EmailNotUnique = '10001',
  FnNotUnique = '10002',
  TinNotUnique = '10003',
}

export enum Measure {
  KG = 'KG',
  LITER = 'LITER',
  METER = 'METER',
  SQUARE_METER = 'SQUARE_METER',
  LINE_METER = 'LINE_METER',
  PIECE = 'PIECE',
  PACK = 'PACK',
}

export enum SpecialErrors {
  RegistrationHistoryLoadingError = 'RegistrationHistoryLoadingError',
  LastTransactionsLoadingError = 'LastTransactionsLoadingError',
  LastVouchersLoadingError = 'LastVouchersLoadingError',
  PreRegisterLoadingError = 'PreRegisterLoadingError',
}

export enum RegistrationMode {
  Auto = 'auto',
  Manual = 'manual',
}

export enum RegistrationStep {
  Account = 'Account',
  Company = 'Company',
}

export enum LegalPersonType {
  Entity = 'ENTITY',
  Individual = 'INDIVIDUAL',
}

export enum MapTypes {
  TAXPAYERS = 'taxpayers',
  NOTARIES = 'notaries',
  TAXI = 'taxi',
}

export enum CurrencyType {
  Catalog = 'catalog',
  Local = 'local',
}

export enum TaxiCompanyTop {
  FIRST = 'taxi-top-first',
  SECOND = 'taxi-top-second',
  THIRD = 'taxi-top-third',
  FOURTH = 'taxi-top-fourth',
  FIFTH = 'taxi-top-fifth',
  OTHERS = 'taxi-top-others',
}

export enum VoucherListCRsType {
  ALL = 'ALL',
  LEGAL_PERSON_AGENT = 'LEGAL_PERSON_AGENT',
  LEGAL_PERSON_PRINCIPAL = 'LEGAL_PERSON_PRINCIPAL',
  AGENT = 'AGENT',
  PRINCIPAL = 'PRINCIPAL',
  LEGAL_PERSON = 'LEGAL_PERSON',
}

export enum CashTransactionType {
  deposit,
  withdraw,
}

export enum mappingShiftTableClasses {
  borderBottom = 'state__table-border--bottom',
  borderTop = 'state__table-border--top',
  bold = 'state__table--bold-text',
}

export interface ICashTransactionReceipt {
  type: CashTransactionType;
  amount: number;
  shiftNumber: number;
  cashier: string;
  crRegistrationNumber: string;
}

export enum CorrectionTypes {
  manual = 'Self',
  order = 'Order',
}
