import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { IPrincipal } from '@/types';

export class PrincipalService extends ApiService {
  public async create(params: IPrincipal) {
    return await axiosInstance.post(ENDPOINTS.principal.create, params);
  }

  public async update(params: IPrincipal) {
    return await axiosInstance.put(ENDPOINTS.principal.update, params);
  }

  public async delete(tin: IPrincipal['tin']) {
    return await axiosInstance.delete(ENDPOINTS.principal.deleteByTin(tin));
  }
}
