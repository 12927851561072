import { UserService } from '@/api/services/userService';
import { ShiftService } from '@/api/services/shiftService';
import { SaleService } from '@/api/services/saleService';
import { CurrencyService } from '@/api/services/currencyService';
import { PrincipalService } from '@/api/services/principalService';
import { BaseCatalogService } from '@/api/services/baseCatalogService';

export default {
  user: new UserService(),
  shift: new ShiftService(),
  sale: new SaleService(),
  currency: new CurrencyService(),
  principal: new PrincipalService(),
  baseCatalog: new BaseCatalogService(),
};
