import { createUrl } from '@/utils';

const sale = createUrl('cash-register');

const nomenclature = sale('nomenclature');
const baseCatalogue = sale('base-catalogue');
const sendReceipt = sale('receipt');
const sendCorrectionReceipt = sale('correction');

export const saleEndpoints = {
  nomenclature,
  baseCatalogue,
  sendReceipt,
  sendCorrectionReceipt,
};
