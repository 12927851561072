import { createUrl } from '@/utils';

const currency = createUrl('cash-register/principal');

const create = currency('add');
const update = currency('update');
const deleteByTin = (tin: string | number) => currency(`remove/${tin}`);
const list = currency('list');

export const principalEndpoints = { create, update, deleteByTin, list };
