import { ApiService } from '@/api/services/core';
import { Credentials } from '@/api/services/userService/types';
import { ENDPOINTS } from '@/api/endpoints';
import { axiosAuthInstance, axiosInstance } from '@/api';
import stores from '@/store/core/RootStore';
import { ICrAuthTokens, ITokens, IUser } from '@/types';

export class UserService extends ApiService {
  public login(props: Credentials) {
    return axiosAuthInstance.post<ICrAuthTokens>(ENDPOINTS.auth.login, props);
  }

  public refresh() {
    return axiosAuthInstance.post<ICrAuthTokens>(ENDPOINTS.auth.refresh, null, {
      headers: { 'Refresh-Token': stores?.user.refreshCustomerToken || '' },
    });
  }

  public me() {
    return axiosInstance.get<IUser>(ENDPOINTS.auth.me);
  }

  public startCr(crNumber: string) {
    return axiosAuthInstance.get<ITokens>(ENDPOINTS.auth.startCr(crNumber), {
      headers: { 'Access-Token': stores?.user.customerToken },
    });
  }

  public refreshCr() {
    return axiosAuthInstance.post<ICrAuthTokens>(ENDPOINTS.auth.refresh, null, {
      headers: { 'Refresh-Token': stores?.user.refreshCrToken || '' },
    });
  }
}
