import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { IBaseCurrency } from '@/types';

export class CurrencyService extends ApiService {
  public async list() {
    return await axiosInstance.get<IBaseCurrency[]>(ENDPOINTS.currency.list);
  }

  public async create(params: IBaseCurrency) {
    return await axiosInstance.post(ENDPOINTS.currency.create, params);
  }

  public async update(params: IBaseCurrency) {
    return await axiosInstance.put(ENDPOINTS.currency.update, params);
  }

  public async delete(id: IBaseCurrency['id']) {
    return await axiosInstance.delete(ENDPOINTS.currency.deleteById(id));
  }
}
