import UserSession from '@/store/session';
import { IPrincipal } from '@/types';
import services from '@/api/services';
import StoreConstructor from '@/store/core/StoreConstructor';
import { computed } from 'mobx';

export class Principals extends StoreConstructor {
  @computed get principals() {
    return UserSession.principals;
  }

  public async createPrincipal(principal: IPrincipal): Promise<void> {
    await services.principal.create(principal);
    this.principals.push(principal);
  }

  public async updatePrincipal(principal: IPrincipal): Promise<void> {
    await services.principal.update(principal);

    const index = this.principals.findIndex((p) => p.tin === principal.tin);
    if (index > -1) {
      this.principals[index] = principal;
    }
  }

  public async deletePrincipal(tin: string): Promise<void> {
    await services.principal.delete(tin);

    const index = this.principals.findIndex(
      (principal: IPrincipal) => principal.tin === tin,
    );
    if (index > -1) {
      this.principals.splice(index, 1);
    }
  }
}
