import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import stores from '@/store/core/RootStore';
import router from '@/router';
import services from '@/api/services';
import { ICrAuthTokens } from '@/types';

type ExtendedAxiosRequestConfig =
  | (AxiosRequestConfig & {
      isRetry: boolean;
    })
  | undefined;

const crApiHeaders = {
  Ccrmodel: 'Mobile',
  Ccrversion: 1,
};

// const isProduction = process.env.NODE_ENV === 'production';
const baseURL = 'https://sto-kkm-ui-ais-webcr.dev-24x7.com/api/';

const axiosInstance = axios.create({
  baseURL,
  headers: crApiHeaders,
});
export const axiosAuthInstance = axios.create({
  baseURL,
  headers: crApiHeaders,
});

const REQUEST_INTERCEPTORS = [
  (config: InternalAxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Access-Token'] = stores?.user.crToken || '';
    }
    return config;
  },
  (error: any) => error,
];

const RESPONSE_INTERCEPTORS = [
  (response: AxiosResponse) => response,
  async (error: any) => {
    console.log(error);
    const config = error.config as ExtendedAxiosRequestConfig;
    if (error?.response?.status === 401 && config && !config.isRetry) {
      config.isRetry = true;
      try {
        isRefreshing = isRefreshing ? isRefreshing : refresh();
        const data = await isRefreshing;
        isRefreshing = null;
        stores.user.setCrKey(data);
        return axiosInstance.request(config);
      } catch (error) {
        console.error(error);
        stores.user.removeAuthKey();
        await router.push({ name: 'auth-login-index' });
      }
    }
    return Promise.reject(error);
  },
];

const refresh = async () => {
  const response = await services.user.refreshCr();
  return response.data;
};

let isRefreshing: Promise<ICrAuthTokens> | null = null;

axiosInstance.interceptors.request.use(...REQUEST_INTERCEPTORS);
axiosInstance.interceptors.response.use(...RESPONSE_INTERCEPTORS);

export { axiosInstance };
