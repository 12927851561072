import { action, observable, computed } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import router from '@/router';
import { Credentials } from '@/api/services/userService/types';
import services from '@/api/services';
import UserSession from '@/store/session';
import {
  IAvailableCr,
  ICrAuthModel,
  ICrAuthTokens,
  ITokens,
  IUser,
} from '@/types';

export default class User extends StoreConstructor {
  userSession = UserSession;
  private TEMP_AUTH_INFO_KEY = 'CR_AUTH_MODEL';
  private SELECTED_CR_KEY = 'SELECTED_CR';

  @observable key: ICrAuthTokens = null;
  @observable crTokens: null | ITokens = null;
  @observable demoKey: string | null = '';

  get activeCr(): IAvailableCr {
    const json = localStorage.getItem(this.SELECTED_CR_KEY);
    return json ? JSON.parse(json) : undefined;
  }

  set activeCr(value: IAvailableCr | undefined) {
    if (value) {
      localStorage.setItem(this.SELECTED_CR_KEY, JSON.stringify(value));
    } else {
      localStorage.removeItem(this.SELECTED_CR_KEY);
    }
  }

  constructor(stores: RootStores) {
    super(stores);
    if (localStorage.getItem('demoKey'))
      this.demoKey = localStorage.getItem('demoKey');

    let key: ICrAuthTokens = null;
    const lsAuthKey = localStorage.getItem('auth');
    if (lsAuthKey) key = JSON.parse(lsAuthKey);
    this.setAuthKey(key);

    let crKey: ITokens | null = null;
    const lsCrKey = localStorage.getItem('crAuth');
    if (lsCrKey) crKey = JSON.parse(lsCrKey);
    this.setCrKey(crKey);
  }

  @computed get isLoggedIn() {
    return !!this.key;
  }

  @computed get refreshCustomerToken() {
    return this.key?.refreshToken;
  }

  @computed get customerToken() {
    return this.key?.accessToken;
  }

  @computed get crToken() {
    return this.crTokens?.accessToken;
  }

  @computed get refreshCrToken() {
    return this.crTokens?.refreshToken;
  }

  @computed get activeCrBlocked(): boolean | undefined {
    return this.activeCr.blockedByFn || this.activeCr.inactiveCr;
  }

  @action.bound setActiveCrDeregistered() {
    this.activeCr = {
      ...this.activeCr,
      deregistered: true,
    };
  }

  @action.bound setActiveCrBlocked() {
    const blockedByFn = this.userSession.isFnExpired;
    const inactiveCr = this.userSession.isCrInactive;
    this.activeCr = {
      ...this.activeCr,
      blockedByFn,
      inactiveCr,
    } as IAvailableCr;
  }

  @action.bound isRefreshTokenExpired(tokens: ITokens | null = null): boolean {
    tokens = tokens ? tokens : this.crTokens;
    return (
      !tokens ||
      !tokens.refreshToken ||
      !tokens.refreshTokenTTL ||
      new Date() > new Date(tokens.refreshTokenTTL)
    );
  }

  @action.bound writeCrAuthModel(info: Partial<ICrAuthModel> | null) {
    if (info) {
      localStorage.setItem(this.TEMP_AUTH_INFO_KEY, JSON.stringify(info));
    } else {
      localStorage.removeItem(this.TEMP_AUTH_INFO_KEY);
    }
  }

  removeModelAndTokens(): void {
    if (this.crAuthModel) {
      this.writeCrAuthModel(null);
    }
    this.crTokens = null;
  }

  openCrAuth(chooseCrTokens: ICrAuthTokens) {
    this.writeCrAuthModel({
      canRegister: chooseCrTokens?.canRegister,
      cashierName: chooseCrTokens?.cashierName,
      name: chooseCrTokens?.name,
      registrators: chooseCrTokens?.registrators,
      tin: chooseCrTokens?.tin,
    });
  }

  get crAuthModel(): ICrAuthModel {
    const json = localStorage.getItem(this.TEMP_AUTH_INFO_KEY);
    return json ? JSON.parse(json) : undefined;
  }

  @action setAuthKey(key: ICrAuthTokens) {
    localStorage.setItem('auth', JSON.stringify(key));
    this.key = key;
  }

  @action removeAuthKey() {
    localStorage.removeItem('auth');
    this.key = null;
  }

  @action setCrKey(key: ITokens | null) {
    localStorage.setItem('crAuth', JSON.stringify(key));
    this.crTokens = key;
  }

  @action removeCrKey() {
    localStorage.removeItem('crAuth');
    this.crTokens = null;
  }

  @action initUserSession(opts: IUser): void {
    return UserSession.init(opts);
  }

  @action async refreshUserSession() {
    try {
      const userSessionOpts = await services.user.me();
      this.initUserSession(userSessionOpts.data);
    } catch (e) {
      console.error(e);
    }
  }

  @action async login(params: Credentials) {
    try {
      const response = await services.user.login(params);
      this.setAuthKey(response.data);
      await router.push({ name: 'auth-login-choose-cr' });
    } catch (e) {
      this.removeAuthKey();
      this.removeCrKey();
      console.error(e);
    }
  }

  @action async startCr(cr: IAvailableCr) {
    try {
      const response = await services.user.startCr(cr.registrationNumber);
      this.activeCr = cr;
      this.setCrKey(response.data);
      await router.push({ name: 'sale-index' });
    } catch (e) {
      this.removeCrKey();
      console.error(e);
    }
  }
}
