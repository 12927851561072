import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { Nomenclature } from '@/models/nomenclature';
import {
  IBaseCatalogResponse,
  ICorrectionReceiptPostModel,
  IDefaultReceiptPostModel,
} from '@/types';
import { colorsSet } from '@/types/constants';

export class SaleService extends ApiService {
  private static itemsColorsIdx = 0;
  private static categoriesColorIdx = 0;
  private static colors = colorsSet;

  public async nomenclature() {
    return await axiosInstance.get<Nomenclature>(ENDPOINTS.sale.nomenclature);
  }

  public async baseCatalogue() {
    return await axiosInstance.get<IBaseCatalogResponse>(
      ENDPOINTS.sale.baseCatalogue,
    );
  }

  public async sendReceipt(receipt: IDefaultReceiptPostModel) {
    return await axiosInstance.post<IDefaultReceiptPostModel>(
      ENDPOINTS.sale.sendReceipt,
      { receipt },
    );
  }

  public async sendCorrectionReceipt(
    receipt: Partial<ICorrectionReceiptPostModel>,
  ) {
    return await axiosInstance.post<ICorrectionReceiptPostModel>(
      ENDPOINTS.sale.sendCorrectionReceipt,
      { receipt },
    );
  }
}
