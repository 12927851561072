import { IBaseCurrency, ICurrency } from '@/types';
import { CURRENCY_FRACTURE_PART } from '@/types/constants';
import { CurrencyType } from '@/types/enums';
import services from '@/api/services';
import StoreConstructor from '@/store/core/StoreConstructor';

export class Currency extends StoreConstructor {
  public async getCurrencyList(): Promise<ICurrency[]> {
    const currencyList = (await services.currency.list()).data;
    return currencyList.map((currency: IBaseCurrency) => ({
      ...currency,
      sellExchangeRate: currency.sellExchangeRate * CURRENCY_FRACTURE_PART,
      buyExchangeRate: currency.buyExchangeRate * CURRENCY_FRACTURE_PART,
      isEdit: false,
      type: CurrencyType.Catalog,
      basePrice: 0,
    }));
  }

  public async createCurrency(currency: ICurrency): Promise<void> {
    const baseCurrency: IBaseCurrency = {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      amount: currency.amount,
      buyExchangeRate: currency.buyExchangeRate,
      sellExchangeRate: currency.sellExchangeRate,
      modifyDate: currency.modifyDate,
      hidden: currency.hidden,
    };

    await services.currency.create(baseCurrency);
  }

  public async updateCurrency(currency: ICurrency): Promise<void> {
    const baseCurrency: IBaseCurrency = {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      amount: currency.amount,
      buyExchangeRate: currency.buyExchangeRate,
      sellExchangeRate: currency.sellExchangeRate,
      modifyDate: currency.modifyDate,
      hidden: currency.hidden,
    };

    await services.currency.update(baseCurrency);
  }

  public async deleteCurrency(currency: ICurrency): Promise<void> {
    await services.currency.delete(currency.id);
  }
}
